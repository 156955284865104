import axios from 'axios'
import { authString } from '../context/services/AuthHeader'
import toast from '../components/molecules/RwToast'

export const API_GET = 'get'
export const API_POST = 'post'
export const API_PUT = 'put'
export const API_PATCH = 'patch'
export const API_DELETE = 'delete'
export interface ApiOptions {
  throwError?: boolean
}
export interface IApi {
  method?: 'get' | 'delete' | 'post' | 'put' | 'patch'
  url: string
  data?: any
  options?: ApiOptions
}
class Api {
  static async call(props: IApi) {
    const { method, url, data, options } = props
    axios.defaults.headers.common['Authorization'] = authString()
    const response: any = await axios({ method, url, data }).catch((error) => {
      if (options?.throwError) {
        throw error
      } else {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          toast.error(error.response.data.message)
        } else if (error.request) {
          console.log('error.request', error.request)
        } else {
          console.log('Error', error.message)
          toast.error(error.response.data.message)
        }
      }
    })

    return response
  }

  static async get(url: string) {
    return Api.call({ method: API_GET, url: url })
  }

  static async post(url: string, data: any, options?: Object) {
    return Api.call({ method: API_POST, url: url, data: data, options })
  }

  static async put(url: string, data: any, options?: Object) {
    return Api.call({ method: API_PUT, url: url, data: data, options })
  }

  static async patch(url: string, data: any) {
    return Api.call({ method: API_PATCH, url: url, data: data })
  }

  static async delete(url: string) {
    return Api.call({ method: API_DELETE, url: url })
  }
}

export { Api as api }
