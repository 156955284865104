import React, { useEffect, useState } from 'react'
import { Card, Form, Container, Row, Col } from 'react-bootstrap'
import RwFormikInput from '../../molecules/RwFormikInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges } from '../../../helpers'
import RwCard from '../../molecules/RwCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RwSubmitButton from '../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../molecules/RwButton/RwCloseButton'
import RwButtonGroup from '../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import RoleSearchModal from '../../organisms/Common/Modal/RoleSearchModal'
import RwDisplayField from '../../molecules/RwDisplayField'
import AuditLog from '../../organisms/AuditLog'
import { useIamContext } from '../../../context/iam/IamProvider'
import { IRoleData, IUserProfileData } from '../../../context/iam/types'
import RwTable from '../../molecules/RwTable'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import { BsPencilSquare } from 'react-icons/bs'
import RwNavHeader from '../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../molecules/RwNav/types'
import { IAM_UPDATE_PERM } from '../../../constants/iam'
import toast from '../../molecules/RwToast'

export const UserProfileBreadcrumb: React.FC = () => {
  const { userProfile } = useIamContext()
  return (
    <span>
      {userProfile?.profile_name
        ? userProfile?.profile_name
        : 'New User Profile'}
    </span>
  )
}
const UserProfile: React.FC = () => {
  const { id } = useParams()
  const isNew = id === 'new'

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const [onSaveClose, setOnSaveClose] = useState<boolean>(false)
  const [selectedUserProfile, setSelectedUserProfile] =
    useState<IUserProfileData | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const {
    userProfile,
    getUserProfile,
    setUserProfile,
    updateUserProfile,
    deleteUserProfile,
    clearUserProfileContext,
  } = useIamContext()

  useEffect(() => {
    return () => {
      clearUserProfileContext()
    }
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }

    if (isNew) {
      const elems = document.getElementsByName(
        'profile_name',
      ) as NodeListOf<HTMLInputElement>
      if (elems) {
        elems.forEach((elem: HTMLInputElement) => {
          elem.focus()
        })
      }
    } else {
      getUserProfile(id)
    }
  }, [id])

  useEffect(() => {
    if (userProfile?.id) {
      setSelectedUserProfile(userProfile)
    }
  }, [userProfile?.id])

  useEffect(() => {
    if (isNew && userProfile?.id) {
      navigate(location.pathname.replace(`/new`, `/${userProfile.id}`))
    }
  }, [userProfile?.id])

  if ((!id || !userProfile) && !isNew) {
    return <></>
  }

  const validationSchema = Yup.object().shape(
    {
      profile_name: Yup.string().required('Required'),
      description: Yup.string(),
    },
    [],
  )

  const initialValues = {
    id: userProfile?.id || '',
    profile_name: userProfile?.profile_name || '',
    description: userProfile?.description || '',
  }

  const onSubmit = (values: any, submitProps: FormikHelpers<any>) => {
    const updatedUserProfileInfo = userProfile
      ? determineChanges(initialValues, values, ['id'], isNew)
      : { ...values, id: null }

    updateUserProfile(updatedUserProfileInfo, handleOnSaveClose)
  }

  const handleOnSaveClose = () => {
    if (onSaveClose) {
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  const handleDeleteClick = () => {
    setShowConfirmDelete(true)
  }

  const handleDeleteConfirm = () => {
    deleteUserProfile(id, handleClose)
  }

  const handleManageRoles = () => {
    if (userProfile?.id) {
      navigate(`${location.pathname}/roles`)
    } else {
      toast.error('Please save the user profile first before managing roles.')
    }
  }
  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, isValid, dirty, resetForm }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <RwNavHeader
              pinKey={PinKey.IAM_USER_PROFILES_DETAIL}
              title={`User Profile ${userProfile?.profile_name}`}
              handleClose={handleClose}
            >
              <RwNavBar>
                <RwNavItem
                  variant="remove"
                  permission={IAM_UPDATE_PERM}
                  onClick={handleDeleteClick}
                />
                <RwNavItem
                  variant="key"
                  title="Manage Roles"
                  onClick={handleManageRoles}
                />

                <RwNavItem
                  fill
                  variant="revert"
                  disabled={!dirty}
                  onClick={() => {
                    resetForm()
                    setUserProfile(selectedUserProfile)
                  }}
                />
                <RwNavItem
                  variant="save"
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    if (dirty && isValid) {
                      handleSubmit()
                    }
                  }}
                />
                <RwNavItem
                  variant="save"
                  title="Save & Close"
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    if (dirty && isValid) {
                      setOnSaveClose(true)
                      handleSubmit()
                    }
                  }}
                />
              </RwNavBar>
            </RwNavHeader>

            <div className="p-4">
              <Container className="m-0 p-0" fluid>
                <Row>
                  <Col md={12} lg={5}>
                    <RwCard className="">
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>UserProfile Details</div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        <RwFormikInput
                          name="profile_name"
                          label="UserProfile Name"
                        />
                        <RwFormikInput
                          as="textarea"
                          name="description"
                          label="Description"
                          height="120px"
                        />
                      </Card.Body>
                    </RwCard>
                  </Col>
                  <Col md={12} lg={4}>
                    <RwCard className="">
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>Roles</div>
                          <div
                            className="ms-2 cursor-pointer"
                            onClick={handleManageRoles}
                          >
                            <BsPencilSquare />
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        {userProfile?.roles && (
                          <RwTable>
                            {userProfile?.roles?.map((role: IRoleData) => {
                              return (
                                <>
                                  <RwTableBody>
                                    <RwTableRow>
                                      <RwTableCell className="p-1">
                                        {role.role_name}
                                      </RwTableCell>
                                    </RwTableRow>
                                  </RwTableBody>
                                </>
                              )
                            })}
                          </RwTable>
                        )}
                      </Card.Body>
                    </RwCard>
                  </Col>
                  <Col md={12} lg={3}>
                    <RwCard>
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>UserProfile Metadata</div>
                          <AuditLog
                            table="user_profiles"
                            keyValue={id}
                            fieldMap={{ profile_name: 'UserProfile Name' }}
                          />
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        <RwDisplayField
                          value={userProfile?.created_at?.toString()}
                          label="Created Date"
                        />
                        <RwDisplayField
                          value={userProfile?.updated_at?.toString()}
                          label="Updated Date"
                        />
                      </Card.Body>
                    </RwCard>
                  </Col>
                </Row>
              </Container>

              <RwButtonGroup>
                <RwSubmitButton
                  onClick={() => {
                    setOnSaveClose(true)
                  }}
                  disabled={!dirty || !isValid}
                >
                  Save & Close
                </RwSubmitButton>
                <RwSubmitButton disabled={!dirty || !isValid}>
                  Save
                </RwSubmitButton>
                <RwCloseButton onClick={handleClose} />
              </RwButtonGroup>
            </div>
          </Form>
        )}
      </Formik>
      <ModalConfirmation
        showModal={showConfirmDelete}
        setShowModal={setShowConfirmDelete}
        handleConfirm={handleDeleteConfirm}
        title="Delete User Profile"
        confirmButtonText="Delete"
        body={<span className="fs-5">Are you sure you want to delete?</span>}
      />
    </div>
  )
}

export default UserProfile
