import './App.scss'
import './scss/custom.scss'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import Header from './components/organisms/Header'

import { ToastContainer } from 'react-toastify'
import { Flip } from 'react-toastify'
import ModalDeadFish from './components/organisms/Common/Modal/ModalDeadFish'
import { CookiesProvider } from 'react-cookie'
import SideNav from './components/organisms/Menu/SideNav'
import { Desktop } from './components/atoms/responsive'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import MainContent from './components/organisms/Main/MainContent'
import ContextProviders from './context/ContextProviders'

const App = () => {
  return (
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <ContextProviders>
          <DndProvider backend={HTML5Backend}>
            <div className="page-container">
              <Header />
              <div className="d-flex">
                <Desktop>
                  <div className="border-end border-bottom">
                    <SideNav />
                  </div>
                </Desktop>
                <div className="w-100">
                  <MainContent />
                </div>
              </div>
              <ModalDeadFish />
              <ToastContainer
                transition={Flip}
                theme="colored"
                autoClose={3000}
              />
            </div>
          </DndProvider>
        </ContextProviders>
      </CookiesProvider>
    </BrowserRouter>
  )
}

export default App
