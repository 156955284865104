import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useSiteContext } from '../../../context/site/SiteProvider'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import DisplayCheck from '../../atoms/DisplayCheck'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import { useProductsDeals } from '../../../queries/products'
import { useQueryClient } from '@tanstack/react-query'
import { IProductsDeal } from '../../../context/products/types'
import RwNavHeader from '../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../molecules/RwNav/types'

export const ProductsDealsBreadcrumb = () => {
  return <span>Products Deals</span>
}

const ProductsDeals: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data, refetch } = useProductsDeals()

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['productsDeals'])
    }
  }, [])

  const handleCreateButtonClick = () => {
    navigate('/accounting/products/deal')
  }

  const handleRefresh = () => {
    refetch()
  }

  const handleRowDoubleClick = (deal_id: string) => {
    navigate(`/accounting/products/deal/${deal_id}`)
  }

  return (
    <div>
      <RwNavHeader
        pinKey={PinKey.PRODUCTS_DEALS}
        title="Products Deals"
        handleRefresh={handleRefresh}
      >
        <RwNavBar>
          <RwNavItem variant="add" onClick={handleCreateButtonClick} />
        </RwNavBar>
      </RwNavHeader>

      <div className={'p-4'}>
        <RwTable hover>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th">Deal Name</RwTableCell>
              <RwTableCell as="th">Discount</RwTableCell>
              <RwTableCell as="th">Start Date</RwTableCell>
              <RwTableCell as="th">End Date</RwTableCell>
              <RwTableCellCenter as="th">Active</RwTableCellCenter>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {data ? (
              data.map((deal: IProductsDeal, index: number) => {
                return (
                  <RwTableRow
                    onDoubleClick={() => handleRowDoubleClick(deal.deal_id)}
                    key={deal.deal_id}
                  >
                    <RwTableCell>{deal.deal_name}</RwTableCell>
                    <RwTableCell>{deal.deal_fraction + '%'}</RwTableCell>
                    <RwTableCell>{deal.start_date}</RwTableCell>
                    <RwTableCell>{deal.end_date}</RwTableCell>
                    <RwTableCellCenter>
                      <DisplayCheck value={deal.is_active || false} />
                    </RwTableCellCenter>
                  </RwTableRow>
                )
              })
            ) : (
              <></>
            )}
          </RwTableBody>
        </RwTable>
      </div>
    </div>
  )
}

export default ProductsDeals
