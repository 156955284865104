import React, { useEffect, useState } from 'react'
import { Card, Form, Container, Row, Col } from 'react-bootstrap'
import RwFormikInput from '../../molecules/RwFormikInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges } from '../../../helpers'
import RwCard from '../../molecules/RwCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RwSubmitButton from '../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../molecules/RwButton/RwCloseButton'
import RwButtonGroup from '../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import RwDisplayField from '../../molecules/RwDisplayField'
import AuditLog from '../../organisms/AuditLog'
import { useIamContext } from '../../../context/iam/IamProvider'
import { IPermissionData } from '../../../context/iam/types'
import ProtectedForm from '../../molecules/ProtectedForm'
import { IAM_UPDATE_PERM } from '../../../constants/iam'
import RwNavHeader from '../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../molecules/RwNav/types'

export const PermissionBreadcrumb: React.FC = () => {
  const { permission } = useIamContext()
  return (
    <span>
      {permission?.permission_name
        ? permission?.permission_name
        : 'New Permission'}
    </span>
  )
}

const Permission: React.FC = () => {
  const { id } = useParams()
  const isNew = id === 'new'

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [onSaveClose, setOnSaveClose] = useState<boolean>(false)
  const [selectedPermission, setSelectedPermission] =
    useState<IPermissionData | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const {
    permission,
    getPermission,
    setPermission,
    updatePermission,
    deletePermission,
    clearPermissionContext,
  } = useIamContext()

  useEffect(() => {
    return () => {
      clearPermissionContext()
    }
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }

    if (isNew) {
      const elems = document.getElementsByName(
        'permission_name',
      ) as NodeListOf<HTMLInputElement>
      if (elems) {
        elems.forEach((elem: HTMLInputElement) => {
          elem.focus()
        })
      }
    } else {
      getPermission(id)
    }
  }, [id])

  useEffect(() => {
    if (permission?.id) {
      setSelectedPermission(permission)
    }
  }, [permission?.id])

  useEffect(() => {
    if (isNew && permission?.id) {
      navigate(location.pathname.replace(`/new`, `/${permission.id}`))
    }
  }, [permission?.id])

  if ((!id || !permission) && !isNew) {
    return <></>
  }

  const validationSchema = Yup.object().shape(
    {
      permission_name: Yup.string().required('Required'),
      description: Yup.string(),
    },
    [],
  )

  const initialValues = {
    id: permission?.id || '',
    permission_name: permission?.permission_name || '',
    description: permission?.description || '',
  }

  const onSubmit = (values: any, submitProps: FormikHelpers<any>) => {
    const updatedPermissionInfo = permission
      ? determineChanges(initialValues, values, ['id'], isNew)
      : { ...values, id: null }

    updatePermission(updatedPermissionInfo, handleOnSaveClose)
  }

  const handleOnSaveClose = () => {
    if (onSaveClose) {
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  const handleDeleteClick = () => {
    setShowConfirmDelete(true)
  }

  const handleDeleteConfirm = () => {
    deletePermission(id, handleClose)
  }

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ProtectedForm permission={IAM_UPDATE_PERM}>
              <RwNavHeader
                pinKey={PinKey.IAM_PERMISSIONS_DETAIL}
                title={`Permission - ${permission?.permission_name}`}
                handleClose={handleClose}
              >
                <RwNavBar>
                  <RwNavItem
                    permission={IAM_UPDATE_PERM}
                    variant="remove"
                    onClick={handleDeleteClick}
                  />

                  <RwNavItem
                    fill
                    variant="revert"
                    disabled={!dirty}
                    onClick={() => {
                      resetForm()
                      setPermission(selectedPermission)
                    }}
                  />
                  <RwNavItem
                    permission={IAM_UPDATE_PERM}
                    variant="save"
                    disabled={!dirty || !isValid}
                    onClick={() => {
                      if (dirty && isValid) {
                        handleSubmit()
                      }
                    }}
                  />
                  <RwNavItem
                    permission={IAM_UPDATE_PERM}
                    variant="save"
                    title="Save & Close"
                    disabled={!dirty || !isValid}
                    onClick={() => {
                      if (dirty && isValid) {
                        setOnSaveClose(true)
                        handleSubmit()
                      }
                    }}
                  />
                </RwNavBar>
              </RwNavHeader>

              <div className="p-4">
                <Container className="m-0 p-0" fluid>
                  <Row>
                    <Col md={12} lg={9}>
                      <RwCard className="">
                        <Card.Header className="fw-bold">
                          <div className="d-flex">
                            <div>Permission Details</div>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-2">
                          <fieldset>
                            <RwFormikInput
                              name="permission_name"
                              label="Permission Name"
                            />
                            <RwFormikInput
                              as="textarea"
                              name="description"
                              label="Description"
                              height="120px"
                            />
                          </fieldset>
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col md={12} lg={3}>
                      <RwCard>
                        <Card.Header className="fw-bold">
                          <div className="d-flex">
                            <div>Permission Metadata</div>
                            <AuditLog
                              table="permissions"
                              keyValue={id}
                              fieldMap={{}}
                            />
                          </div>
                        </Card.Header>
                        <Card.Body className="p-2">
                          <RwDisplayField
                            value={permission?.created_at?.toString()}
                            label="Created Date"
                          />
                          <RwDisplayField
                            value={permission?.updated_at?.toString()}
                            label="Updated Date"
                          />
                        </Card.Body>
                      </RwCard>
                    </Col>
                  </Row>
                </Container>

                <RwButtonGroup>
                  <RwSubmitButton
                    permission={IAM_UPDATE_PERM}
                    onClick={() => {
                      setOnSaveClose(true)
                    }}
                    disabled={!dirty || !isValid}
                  >
                    Save & Close
                  </RwSubmitButton>
                  <RwSubmitButton
                    permission={IAM_UPDATE_PERM}
                    disabled={!dirty || !isValid}
                  >
                    Save
                  </RwSubmitButton>
                  <RwCloseButton onClick={handleClose} />
                </RwButtonGroup>
              </div>
            </ProtectedForm>
          </Form>
        )}
      </Formik>
      <ModalConfirmation
        showModal={showConfirmDelete}
        setShowModal={setShowConfirmDelete}
        handleConfirm={handleDeleteConfirm}
        title="Delete Permission"
        confirmButtonText="Delete"
        body={<span className="fs-5">Are you sure you want to delete?</span>}
      />
    </div>
  )
}

export default Permission
