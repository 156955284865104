import React from 'react'
import { useUserContext } from '../../../context/user/UserProvider'

interface ISticky {
  [x: string]: any
}

const Sticky: React.FC<ISticky> = ({ children, ...rest }) => {
  const { isDarkMode } = useUserContext()
  return (
    <div
      style={{ zIndex: 1001 }}
      className={`sticky-top ${isDarkMode ? 'dm-body-bg' : 'bg-white'}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Sticky
