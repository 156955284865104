import React from 'react'

import { useUserContext } from '../../context/user/UserProvider'
import { useSiteContext } from '../../context/site/SiteProvider'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { isEmbedded, isHideNavPath } from '../../helpers'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { StaffAccountBreadcrumb } from '../pages/settings/staff-account'
import MobileHeaderMenu from './Header/MobileHeaderMenu'
import { CustomAssortmentBreadcrumb } from '../pages/products/custom-assortment'
import { ProductsDealBreadcrumb } from '../pages/products/products-deal'
import { ProductBreadcrumb } from '../pages/products/products-editor/tabs/pro-line-tab'
import { ProductsDealsBreadcrumb } from '../pages/products/products-deals'
import { ProductsPricingBreadcrumb } from '../pages/products/products-pricing/products-pricing-tab'
import { ExportInvoicesBreadcrumb } from '../pages/accounting/invoice/export'
import { Link } from 'react-router-dom'
import { PermissionBreadcrumb } from '../pages/iam/permission'
import { RoleBreadcrumb } from '../pages/iam/role'
import { UserRolesBreadcrumb } from '../pages/iam/user-roles'
import { UserProfileBreadcrumb } from '../pages/iam/user-profile'
import { BasketOfGoodsDetailBreadcrumb } from '../pages/pricing/summary/basket-of-goods/detail'
import { PhysicalInventoryLogsBreadcrumb } from '../pages/inventory/wms-inventory-taker-logs'
import { PhysicalInventoryBreadcrumb } from '../pages/inventory/wms-inventory-taker'
import { ProductsBreadcrumb } from '../pages/products/products'
import { ProductListBreadcrumb } from '../pages/products/product-lists/detail'

const Breadcrumbs: React.FC = () => {
  const { isDarkMode } = useUserContext()
  const { userShowCrumbs } = useSiteContext()

  const location = useLocation()

  const routes = [
    {
      path: '/settings/staff-accounts/:staff_id',
      breadcrumb: StaffAccountBreadcrumb,
    },
    {
      path: '/products/custom-assortment/:case_id/:version',
      breadcrumb: CustomAssortmentBreadcrumb,
    },
    {
      path: '/accounting/products/deals',
      breadcrumb: ProductsDealsBreadcrumb,
    },
    {
      path: '/accounting/product-pricing-projection-versions/:version_id/basket/:basket_of_goods_id',
      breadcrumb: BasketOfGoodsDetailBreadcrumb,
    },
    {
      path: '/accounting/products/deal/:deal_id',
      breadcrumb: ProductsDealBreadcrumb,
    },
    {
      path: '/accounting/products-pricing/:products_id/:tab',
      breadcrumb: ProductsPricingBreadcrumb,
    },
    {
      path: '/products/editor',
      breadcrumb: ProductsBreadcrumb,
    },
    {
      path: '/products/editor/:products_id',
      breadcrumb: ProductBreadcrumb,
    },
    {
      path: 'accounting/invoice/export',
      breadcrumb: ExportInvoicesBreadcrumb,
    },
    {
      path: '/iam/permissions/:id',
      breadcrumb: PermissionBreadcrumb,
    },
    {
      path: '/iam/roles/:id',
      breadcrumb: RoleBreadcrumb,
    },
    {
      path: '/iam/users/:staff_id',
      breadcrumb: UserRolesBreadcrumb,
    },
    {
      path: '/iam/user-profiles/:id',
      breadcrumb: UserProfileBreadcrumb,
    },
    {
      path: '/products-pro-line/:products_id',
      breadcrumb: ProductBreadcrumb,
    },
    {
      path: '/wms/inventory-taker/:physical_inventory_id',
      breadcrumb: PhysicalInventoryLogsBreadcrumb,
    },
    {
      path: '/wms/inventory-taker',
      breadcrumb: PhysicalInventoryBreadcrumb,
    },
    {
      path: '/settings/product-lists/:id',
      breadcrumb: ProductListBreadcrumb,
    },
  ]

  const excludePaths = null
  const noLinkPaths = [
    '/settings',
    '/wms',
    '/orders',
    '/customers',
    '/accounting',
    '/products',
    '/warehouse',
    '/inventory',
  ]
  const options = excludePaths ? { excludePaths: excludePaths } : {}
  const breadcrumbs = useBreadcrumbs(routes, options)

  // if (isHideNavPath(location.pathname) || !userShowCrumbs || isEmbedded()) {
  if (isHideNavPath(location.pathname) || !userShowCrumbs) {
    return <></>
  }

  return (
    <>
      <Breadcrumb
        className={`p-3 text-capitalize rw-breadcrumb fs-6 ${
          isDarkMode
            ? 'rw-breadcrumb-dm bg-dm-body-bg'
            : 'rw-breadcrumb-lm bg-white'
        }`}
      >
        <MobileHeaderMenu />

        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const active =
            index === breadcrumbs.length - 1 ||
            noLinkPaths.includes(match.pathname) ||
            isEmbedded()

          return (
            <Breadcrumb.Item
              active={active}
              key={match.pathname}
              className="text-decoration-none"
              linkAs={Link}
              linkProps={{ to: match.pathname }}
            >
              {/* {isEmbedded() ? breadcrumb : <span>{breadcrumb}</span>} */}
              <span>{breadcrumb}</span>
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>
    </>
  )
}

export default Breadcrumbs
