import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap'
import ProductSearch from '../../organisms/Product/ProductSearch'
import RwCard from '../../molecules/RwCard'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import { Formik, FormikHelpers } from 'formik'
import RwFormikInput from '../../molecules/RwFormikInput'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import * as Yup from 'yup'
import RwFormikHiddenInput from '../../molecules/RwFormikHiddenInput'
import { IDealProduct, IPriceLevel } from '../../../context/products/types'
import { useProductContext } from '../../../context/products/ProductProvider'
import RwSubmitButton from '../../molecules/RwButton/RwSubmitButton'
import CdnImg from '../../organisms/Common/CdnImg'
import RwFormikOptionSelect from '../../molecules/RwFormikOptionSelect'
import { BsFillTagFill } from 'react-icons/bs'
import {
  GiFireworkRocket,
  GiTakeMyMoney,
  GiOakLeaf,
  GiFlowerPot,
} from 'react-icons/gi'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import { FaPiggyBank, FaSun, FaSnowflake } from 'react-icons/fa'
import RowContextMenu from '../../molecules/RwTable/RowContextMenu'
import RowContextMenuItem from '../../molecules/RwTable/RowContextMenuItem'
import { API_PRODUCTS_URL } from '../../../context/apiconfig'
import { api } from '../../../helpers/Api'
import toast from '../../molecules/RwToast'
import { LONG_DEBOUNCE_DELAY } from '../../../helpers'
import useDebounce from '../../../hooks/useDebounce'
import RwNavHeader from '../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../molecules/RwNav/types'

export const ProductsDealBreadcrumb: React.FC = () => {
  const { productsDeal } = useProductContext()
  return <span>{productsDeal ? productsDeal?.deal_name : 'New Deal'}</span>
}

interface ILevelPrice {
  price_levels_id: string
  price_levels_name: string
  final_price: string
}

const ProductsDeal: React.FC = () => {
  const { deal_id } = useParams()
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const navigate = useNavigate()
  const {
    getDealWithProducts,
    productsDeal,
    dealProducts,
    getActivePriceLevels,
    activePriceLevels,
    cleanupDealContext,
    cleanupPriceLevelsContext,
    addProductsToDeal,
    removeProductsFromDeal,
    downloadProductSpreadsheetTemplate,
    submitProductSpreadsheet,
  } = useProductContext()

  const [currentProductsId, setCurrentProductsId] = useState<string>('')
  const [productDiscount, setProductDiscount] = useState<string>('')
  const debouncedProductDiscount = useDebounce(
    productDiscount,
    LONG_DEBOUNCE_DELAY,
  )

  useEffect(() => {
    if (deal_id) {
      getDealWithProducts(deal_id)
    }
    getActivePriceLevels()

    return () => {
      cleanupDealContext()
      cleanupPriceLevelsContext()
    }
  }, [])

  useEffect(() => {
    if (deal_id && productDiscount !== '') {
      updateProductDiscount(currentProductsId, productDiscount, deal_id)
    }
  }, [debouncedProductDiscount])

  const handleClose = () => {
    navigate(-1)
  }

  const handleAddToDealClick = (products_id: string) => {
    addProductsToDeal([products_id], deal_id || '')
  }

  const handleRemoveFromDealClick = (products_id: string) => {
    removeProductsFromDeal([products_id], deal_id || '')
  }

  const handleDealSpreadsheetClick = () => {
    downloadProductSpreadsheetTemplate('deal')
  }

  const handleUploadClick = () => {
    setShowUploadModal(true)
  }

  const handleFileChange = (file: File | null) => {
    const filename = file?.name
    if (filename?.includes('deal_products') || filename === null) {
      document
        .getElementById('file')
        ?.setAttribute('value', file ? file.name : '')
      setFile(file ? file : null)
    } else {
      document.getElementById('file')?.setAttribute('value', '')
      toast.error('Invalid file')
    }
  }

  const handleHideUploadModal = () => {
    setShowUploadModal(false)
    setFile(null)
  }

  const handleFileUploadSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (deal_id && file) {
      setIsUploading(true)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('filename', file.name)
      formData.append('sheet_type', 'deal')
      formData.append('deal_id', deal_id)

      await submitProductSpreadsheet(formData)

      getDealWithProducts(deal_id)
    } else {
      toast.error('Something went wrong, make sure a deal is selected first.')
    }

    setIsUploading(false)
    handleHideUploadModal()
  }

  const handleRemoveAllFromDealClick = () => {
    const ids = dealProducts?.map((product) => product.products_id)
    if (!ids || ids.length === 0) {
      toast.info('No products to remove')
      return
    }
    removeProductsFromDeal(ids, deal_id || '')
  }

  const updateProductDiscount = async (
    productsId: string,
    dealFraction: string,
    dealId: string,
  ) => {
    const response = await api.post(`${API_PRODUCTS_URL}update-deal-product`, {
      productsId,
      dealFraction,
      dealId,
    })

    if (response.data.success) {
      getDealWithProducts(dealId)
      toast.success(response.data.message)
    } else {
      getDealWithProducts(dealId)
      toast.error(response.data.message)
    }
  }

  const iconOptions = [
    { value: 'none', label: 'None' },
    { value: 'BsFillTagFill', label: 'Price Tag' },
    { value: 'GiFireworkRocket', label: 'Firework Rocket' },
    { value: 'GiTakeMyMoney', label: 'Take My Money' },
    { value: 'RiMoneyDollarCircleFill', label: 'Dollar Circle' },
    { value: 'FaPiggyBank', label: 'Piggy Bank' },
    { value: 'FaSun', label: 'Summer Sun' },
    { value: 'FaSnowflake', label: 'Snowflake ' },
    { value: 'GiOakLeaf', label: 'Autumn Leaf' },
    { value: 'GiFlowerPot', label: 'Spring Flowers' },
  ]

  function getDealIcon(icon: string) {
    switch (icon) {
      case 'BsFillTagFill':
        return <BsFillTagFill size="35" />
      case 'GiFireworkRocket':
        return <GiFireworkRocket size="35" />
      case 'GiTakeMyMoney':
        return <GiTakeMyMoney size="35" />
      case 'RiMoneyDollarCircleFill':
        return <RiMoneyDollarCircleFill size="35" />
      case 'FaPiggyBank':
        return <FaPiggyBank size="35" />
      case 'FaSun':
        return <FaSun size="35" />
      case 'FaSnowflake':
        return <FaSnowflake size="35" />
      case 'GiOakLeaf':
        return <GiOakLeaf size="35" />
      case 'GiFlowerPot':
        return <GiFlowerPot size="35" />
      default:
        return <></>
    }
  }

  const initialValues = {
    deal_id: productsDeal?.deal_id || '',
    deal_name: productsDeal?.deal_name || '',
    deal_fraction: productsDeal?.deal_fraction || '',
    start_date: productsDeal?.start_date || '',
    end_date: productsDeal?.end_date || '',
    is_active: productsDeal?.is_active || false,
    icon_path: productsDeal?.icon_path || 'none',
  }

  const validationSchema = Yup.object({
    deal_name: Yup.string().required('Required'),
    deal_fraction: Yup.number()
      .integer('Must be a whole number')
      .required('Required'),
    start_date: Yup.string().required('Required'),
    end_date: Yup.string().required('Required'),
  })

  const onSubmit = async (values: any, submitProps: FormikHelpers<any>) => {
    const response = await api.post(`${API_PRODUCTS_URL}save-deal-details`, {
      values,
    })

    if (response.data.success) {
      toast.success(response.data.message)
      if (!deal_id) {
        navigate(`/accounting/products/deal/${response.data.deal_id}`)
      } else {
        getDealWithProducts(deal_id)
      }
    } else {
      submitProps.resetForm()
      toast.error(response.data.message)
    }
  }

  return (
    <>
      <RwNavHeader
        pinKey={PinKey.PRODUCTS_DEALS_DETAIL}
        title={`Product Deal - ${productsDeal?.deal_name}`}
        handleClose={handleClose}
      >
        <RwNavBar>
          <RwNavItem
            variant="excel"
            title="Deal Products Spreadsheet"
            onClick={handleDealSpreadsheetClick}
          />
          <RwNavItem variant="upload-file" onClick={handleUploadClick} />
        </RwNavBar>
      </RwNavHeader>

      <Container fluid>
        <Row>
          <Col lg={2}>
            <ProductSearch
              handleButtonClick={handleAddToDealClick}
              handleRowDoubleClick={handleAddToDealClick}
              disabledProducts={dealProducts}
              maxHeight="80vh"
            />
          </Col>
          <Col className="p-4" lg={7}>
            <Card className="overflow-auto" style={{ maxHeight: '80vh' }}>
              <RwTable>
                <RwTableHead>
                  <RwTableRow>
                    <RwTableCell as="th" />
                    <RwTableCell as="th">Product Model</RwTableCell>
                    <RwTableCell as="th">Product Name</RwTableCell>
                    <RwTableCellCenter as="th">
                      Discount Percent
                    </RwTableCellCenter>
                    <RwTableCellCenter as="th">Base Price</RwTableCellCenter>
                    <>
                      {activePriceLevels.map(
                        (priceLevel: IPriceLevel, index: number) => {
                          return (
                            <RwTableCellCenter key={index} as="th">
                              {priceLevel.price_levels_name}
                            </RwTableCellCenter>
                          )
                        },
                      )}
                    </>
                    <RwTableCellCenter as="th">
                      <RowContextMenu>
                        <RowContextMenuItem
                          variant="remove all"
                          handleClick={handleRemoveAllFromDealClick}
                        />
                      </RowContextMenu>
                    </RwTableCellCenter>
                  </RwTableRow>
                </RwTableHead>
                <RwTableBody>
                  {dealProducts?.map((product: IDealProduct, index: number) => {
                    return (
                      <RwTableRow key={product.products_id}>
                        <RwTableCell>
                          <CdnImg path={product.image_url} />
                        </RwTableCell>
                        <RwTableCell>{product.products_model}</RwTableCell>
                        <RwTableCell>{product.products_name}</RwTableCell>
                        <RwTableCellCenter>
                          <input
                            className="form-control"
                            id="product_deal_fraction"
                            placeholder={product.product_deal_fraction.toString()}
                            onChange={(e) => {
                              setProductDiscount(e.target.value)
                              setCurrentProductsId(product.products_id)
                            }}
                          />
                        </RwTableCellCenter>
                        <RwTableCellCenter>
                          {'$' + product.base_price}
                        </RwTableCellCenter>
                        <>
                          {product.level_prices.map(
                            (price: ILevelPrice, index: number) => {
                              return (
                                <RwTableCellCenter key={index}>
                                  {'$' + price.final_price}
                                </RwTableCellCenter>
                              )
                            },
                          )}
                        </>
                        <RwTableCellCenter className="border-start">
                          <RowContextMenu>
                            <RowContextMenuItem
                              variant="remove"
                              handleClick={() =>
                                handleRemoveFromDealClick(product.products_id)
                              }
                            />
                          </RowContextMenu>
                        </RwTableCellCenter>
                      </RwTableRow>
                    )
                  })}
                </RwTableBody>
              </RwTable>
            </Card>
          </Col>
          <Col className="p-4" lg={3}>
            <RwCard>
              <Card.Body>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  {({ handleSubmit, values, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <RwFormikHiddenInput name="deal_id" />
                      <RwFormikInput name="deal_name" label="Deal Name" />
                      <RwFormikInput
                        name="deal_fraction"
                        label="Deal Discount Percent"
                      />
                      <br />
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          name="start_date"
                          type="date"
                          size="lg"
                          value={values.start_date || ''}
                          onChange={(e) => {
                            setFieldValue('start_date', e.target.value)
                          }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          name="end_date"
                          type="date"
                          size="lg"
                          value={values.end_date}
                          onChange={(e) => {
                            setFieldValue('end_date', e.target.value)
                          }}
                        />
                      </Form.Group>
                      <RwFormikCheckbox
                        type="switch"
                        name="is_active"
                        label="Active"
                      />
                      <br />
                      <div className="d-flex justify-content-center flex-column">
                        <RwFormikOptionSelect
                          name="icon_path"
                          label="Deal Icon"
                          options={iconOptions}
                        />
                        <div className="d-flex justify-content-center">
                          {getDealIcon(values.icon_path)}
                        </div>
                      </div>
                      <RwSubmitButton type="submit">Save</RwSubmitButton>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </RwCard>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showUploadModal}
        size={'lg'}
        onHide={handleHideUploadModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4>
            Add Products
            {productsDeal?.deal_name ? ' to ' + productsDeal?.deal_name : ''}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Upload a filled out spreadsheet to bulk add or update multiple
            products. The system will use the filename to determine the type of
            sheet you are uploading,{' '}
            <strong>
              please ensure you are using the correct template and that you
              haven't changed the filename
            </strong>
            . Formatting rules and further instructions for each spreadsheet
            column are as follows:
          </p>
          <p>
            <strong>Products Id:</strong> Required. Numbers only, unique to each
            product.
          </p>
          <p>
            <strong>Products Model:</strong> Not required.
          </p>
          <p>
            <strong>Product Deal Discount %:</strong> Not required. Integers and
            empty cells only. An empty cell will result in the product receiving
            the deal's default discount percent.
          </p>
          <Form onSubmit={handleFileUploadSubmit}>
            <Form.Control
              type="file"
              name="file"
              id="file"
              onChange={(e: any) => {
                handleFileChange(e.target.files ? e.target.files[0] : null)
              }}
            />
            <br />
            <br />
            <RwSubmitButton
              className="float-end"
              disabled={file ? false : true}
            >
              {isUploading ? <Spinner animation="border" /> : 'Submit'}
            </RwSubmitButton>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProductsDeal
