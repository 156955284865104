import React from 'react'
import { useUserContext } from '../../../context/user/UserProvider'
import RwInputGroup from '../RwInputGroup'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import { setInputByName } from '../../../helpers'

export interface ISearchInput {
  className?: string
  handleChange: (searchText: string) => void
  handleClear?: () => void
  placeholder?: string
  minLength?: number
  width?: string
  name?: string
  [x: string]: any
}

const RwNav: React.FC<ISearchInput> = ({
  handleChange,
  handleClear = null,
  className = '',
  placeholder = 'Search',
  minLength = 3,
  width = '300px',
  name = '',
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  const clearSearchButton = handleClear ? (
    <Button
      variant="outline-secondary"
      onClick={() => {
        setInputByName(name, '')
        handleClear()
      }}
    >
      <OverlayTrigger placement={'right'} overlay={<Tooltip>Clear</Tooltip>}>
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  ) : (
    <></>
  )

  return (
    <RwInputGroup
      name={name}
      placeholder={placeholder}
      className={`${className} p-3`}
      onChange={(e: any) => {
        if (e.target.value.length >= minLength || e.target.value.length === 0) {
          handleChange(e.target.value)
        }
      }}
      style={{ width: width }}
      suffix={clearSearchButton}
      {...rest}
    />
  )
}

export default RwNav
