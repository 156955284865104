import React, { useEffect, useState } from 'react'
import RwNavBar from './RwNavBar'
import RwNavItem from './RwNavItem'
import Sticky from '../../atoms/Sticky'
import { useCookies } from 'react-cookie'
import { IRwNavHeader } from './types'

const RwNavHeader: React.FC<IRwNavHeader> = ({
  children,
  title,
  pinKey,
  handleClose,
  handleRefresh,
}) => {
  const [pinned, setPinned] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  const togglePinned = () => {
    if (pinKey) {
      setCookie(`toolbar-pinned-${pinKey}`, !pinned)
    }
    setPinned(!pinned)
  }

  useEffect(() => {
    if (pinKey) {
      setPinned(cookies[`toolbar-pinned-${pinKey}`])
    }
  }, [pinKey])

  const header = (
    <RwNavBar title={handleRefresh ? null : title}>
      {handleRefresh ? (
        <RwNavItem title={title} variant="refresh" onClick={handleRefresh} />
      ) : (
        <></>
      )}
      <RwNavItem
        variant={pinned ? 'pinned' : 'unpinned'}
        onClick={togglePinned}
        tooltip={pinned ? 'Unpin Toolbars' : 'Pin Toolbars'}
      />
      {handleClose ? (
        <RwNavItem fill variant="close" onClick={handleClose} />
      ) : (
        <></>
      )}
    </RwNavBar>
  )
  return (
    <>
      {pinned ? (
        <Sticky>
          <>
            {header}
            {children}
          </>
        </Sticky>
      ) : (
        <>
          {header}
          {children}
        </>
      )}
    </>
  )
}

export default RwNavHeader
